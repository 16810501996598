


















































































import L, { LatLng, divIcon, LeafletMouseEvent } from 'leaflet'
import Vue from 'vue'
//@ts-ignore
import { LMovingMarker } from 'vue2-leaflet-plugin-movingmarker'
import { LCircle, LPolyline, LMarker, LTooltip } from 'vue2-leaflet'
import {
  EDetectionTrailStyle,
  EDetectionState,
  IDetection,
  IDetectionPosition,
  COLOR_THREAT,
  COLOR_FRIEND
} from '@/store/modules/detection/types'
import anime from 'animejs'
import { mapActions, mapState } from 'vuex'
import { humanReadableTargetID } from '@/utils/utils'
import Speedleader from '@/components/Map/Detection/SensorFusion/Speedleader.vue'

const props = {
  position: {
    type: Object as () => IDetectionPosition,
    default: () => ({
      lat: 0,
      lng: 0
    })
  },
  detection: {
    type: Object as () => IDetection,
    default: () => {}
  },
  moveDuration: {
    type: Number,
    default: 650
  },
  maxRadius: {
    type: Number,
    default: 100
  },
  isThreat: {
    type: Boolean,
    default: true
  },
  trailStyle: {
    type: Number as () => EDetectionTrailStyle,
    default: EDetectionTrailStyle.LINE
  },
  hideLocationVariance: {
    type: Boolean,
    default: false
  },
  interactive: {
    type: Boolean,
    default: true
  },
  isCameraTracked: {
    type: Boolean,
    default: false
  }
}

const BAR_THRESHOLD_1 = 0.2
const BAR_THRESHOLD_2 = 0.4
const BAR_THRESHOLD_3 = 0.6
const BAR_THRESHOLD_4 = 0.8

export default Vue.extend({
  name: 'FusionDetectionDot',
  props,
  components: {
    Speedleader,
    LMarker,
    LMovingMarker,
    LCircle,
    LPolyline,
    LTooltip
  },
  data() {
    return {
      trails: [],
      defaultZoom: 1.25,
      confidence: {
        location: 0,
        object: 0
      },
      markerOptions: {
        zIndexOffset: 1000
      },
      iconWidth: 50,
      iconHeight: 50,
      timer: null,
      markerPosition: { lat: 0, lng: 0 },
      locationConfidenceRadius: 0,
      detectionColorsMap: [
        '#0080F7', // blue
        '#1991F8',
        '#33A2FA',
        '#4CB2FB',
        '#65C3FC',
        '#FBA186',
        '#F87D67',
        '#F95E54',
        '#F93F41',
        '#FA1F2E',
        '#FA001B', // red
        '#4CAF50' // green
      ]
    }
  },
  created() {
    this.markerPosition = { lat: this.position.lat, lng: this.position.lng }
    this.trails.push(this.position)
  },
  computed: {
    ...mapState(['theme']),
    ...mapState('detection', ['selectedDetections']),
    ...mapState('users', ['isDeveloper', 'developerSettings']),
    detectionKey(): string {
      return this.detection.target_id
    },
    showTrailLine(): boolean {
      return (
        this.trailStyle === EDetectionTrailStyle.LINE ||
        this.trailStyle === EDetectionTrailStyle.BOTH
      )
    },
    showTrailDots(): boolean {
      return (
        this.trailStyle === EDetectionTrailStyle.DOTS ||
        this.trailStyle === EDetectionTrailStyle.BOTH
      )
    },
    selected(): boolean {
      return this.selectedDetections.includes(this.detectionKey)
    },
    detectionColor(): string {
      return this.isThreat ? COLOR_THREAT : COLOR_FRIEND
    },
    markerRef(): string {
      return `fusion-marker-${this.detectionKey}`
    },
    objectConfidence(): number {
      return this.detection.probability
    },
    locationConfidence(): number {
      return this.detection.location_variance
    },
    trailCoordinates() {
      return this.trails.map(trail => {
        return [trail.lat, trail.lng]
      })
    },
    bars(): number {
      let val = 0
      if (this.objectConfidence >= BAR_THRESHOLD_1) {
        val = 1
      }
      if (this.objectConfidence >= BAR_THRESHOLD_2) {
        val = 2
      }
      if (this.objectConfidence >= BAR_THRESHOLD_3) {
        val = 3
      }
      if (this.objectConfidence >= BAR_THRESHOLD_4) {
        val = 4
      }
      return val
    },
    useMilStd2525Icons(): boolean {
      return this.theme === 'MIL_STD_2525'
    },
    milStd2525Icon(): string {
      return this.isThreat
        ? 'detection-uav-threat.svg'
        : 'detection-uav-friendly.svg'
    },
    selectorIcon(): L.DivIcon {
      return divIcon({
        className: `fusion-marker-style`,
        html: `<img src="${require('@/assets/sensorfusion/fusion_selector.svg')}" style="width:${this
          .iconWidth + 20}px;height:${this.iconHeight + 20}px"/>`,
        iconSize: [this.iconWidth + 20, this.iconHeight + 20]
      })
    },
    isWhiteListed(): boolean {
      return this.detection.state === EDetectionState.Whitelisted
    },
    isGroundTruth(): boolean {
      return this.detection.classification === 'truth'
    },
    detectionIconColor(): string {
      if (this.isWhiteListed) return this.detectionColorsMap[0] // Blue color if whitelisted
      if (this.isGroundTruth) return this.detectionColorsMap[11] // Green colour if ground truth
      return this.detectionColorsMap[10] // Red colour by default
    },
    detectionIcon(): L.DivIcon {
      const {
        iconHeight,
        iconWidth,
        detectionIconColor,
        bars,
        useMilStd2525Icons,
        milStd2525Icon
      } = this
      const html = useMilStd2525Icons
        ? `<img src="${require('@/assets/mil-std-2525/' +
            milStd2525Icon)}" alt="${milStd2525Icon}"
          width="${iconWidth}" height="${iconHeight}" />`
        : `<svg width="${iconWidth}" height="${iconHeight}" viewBox="0 0 ${iconWidth} ${iconHeight}" fill="none">
           {${
             bars >= 1
               ? `
            <path stroke="${detectionIconColor}" fill="${
                   bars >= 1 ? detectionIconColor : ''
                 }" d="M15.5979 23.1277C15.7223 22.4935 15.9096 21.8716 16.1581 21.2715C16.6481 20.0887 17.3663 19.0138 18.2716 18.1084C19.177 17.203 20.2519 16.4849 21.4348 15.9949C22.0348 15.7464 22.6567 15.559 23.2909 15.4347V10.1204C21.9654 10.2874 20.6652 10.6311 19.4257 11.1445C17.6058 11.8984 15.9522 13.0032 14.5593 14.3961C13.1664 15.789 12.0616 17.4426 11.3078 19.2625C10.7943 20.502 10.4506 21.8022 10.2836 23.1277H15.5979Z"/>
            <path stroke="${detectionIconColor}" fill="${
                   bars >= 2 ? detectionIconColor : ''
                 }" d="M27.1586 15.4347C27.7929 15.559 28.4148 15.7464 29.0148 15.9949C30.1977 16.4849 31.2725 17.203 32.1779 18.1084C33.0833 19.0138 33.8014 20.0886 34.2914 21.2715C34.5399 21.8716 34.7273 22.4935 34.8516 23.1277H40.1659C39.9989 21.8022 39.6552 20.502 39.1418 19.2625C38.3879 17.4426 37.2831 15.789 35.8902 14.3961C34.4973 13.0032 32.8438 11.8984 31.0238 11.1445C29.7843 10.6311 28.4841 10.2874 27.1586 10.1204V15.4347Z"/>
            <path stroke="${detectionIconColor}" fill="${
                   bars >= 3 ? detectionIconColor : ''
                 }" d="M34.8516 26.8777C34.7273 27.5119 34.5399 28.1338 34.2914 28.7339C33.8014 29.9167 33.0833 30.9916 32.1779 31.897C31.2725 32.8024 30.1977 33.5205 29.0148 34.0105C28.4148 34.2591 27.7929 34.4464 27.1586 34.5707V39.8851C28.4842 39.718 29.7843 39.3743 31.0238 38.8609C32.8438 38.1071 34.4973 37.0022 35.8902 35.6093C37.2831 34.2164 38.3879 32.5628 39.1418 30.7429C39.6552 29.5034 39.9989 28.2032 40.1659 26.8777H34.8516Z"/>
            <path stroke="${detectionIconColor}" fill="${
                   bars >= 4 ? detectionIconColor : ''
                 }" d="M23.2909 34.5707C22.6567 34.4464 22.0348 34.2591 21.4348 34.0105C20.2519 33.5205 19.177 32.8024 18.2716 31.897C17.3663 30.9916 16.6481 29.9167 16.1581 28.7339C15.9096 28.1338 15.7223 27.5119 15.5979 26.8777H10.2836C10.4506 28.2032 10.7943 29.5034 11.3078 30.7429C12.0616 32.5628 13.1664 34.2164 14.5593 35.6093C15.9522 37.0022 17.6058 38.1071 19.4257 38.8609C20.6653 39.3743 21.9654 39.7181 23.2909 39.8851V34.5707Z"/>
          `
               : null
           }
          <path fill="${detectionIconColor}" stroke="#212121" stroke-width="1.3" d="M29.8185 25.0028C29.8185 27.5399 27.7618 29.5966 25.2247 29.5966C22.6877 29.5966 20.631 27.5399 20.631 25.0028C20.631 22.4657 22.6877 20.4091 25.2247 20.4091C27.7618 20.4091 29.8185 22.4657 29.8185 25.0028Z" />
         </svg>`
      return divIcon({
        className: `fusion-marker-style ${
          this.isCameraTracked ? 'camera-tracked' : ''
        }`,
        html,
        iconSize: [this.iconWidth, this.iconHeight]
      })
    },
    devTooltipContent() {
      let content = ''
      if (!(this.isDeveloper && this.developerSettings.showDetectionDetails)) {
        return content
      }
      const detectionInfo = {
        id: humanReadableTargetID(this.detectionKey),
        rcs: this.detection.rcs == 0 ? null : this.detection.rcs.toFixed(2),
        height: this.detection.altitude?.toFixed(2) || null,
        prot: this.detection.protocol == '' ? null : this.detection.protocol,
        ssid: this.detection.SSID == '' ? null : this.detection.ssid,
        ven: this.detection.vendor == '' ? null : this.detection.vendor,
        SN:
          this.detection.drone_serial_number == ''
            ? null
            : this.detection.drone_serial_number,
        conf: `${this.detection.radar_confirmed ? ' RAD' : ''}${
          this.detection.rf_confirmed ? ' RF' : ''
        }${this.detection.camera_confirmed ? ' CAM' : ''}${
          this.detection.drone_locator_confirmed ? ' DL' : ''
        }`
      }
      for (const [key, value] of Object.entries(detectionInfo)) {
        if (detectionInfo[key] && value) {
          if (this.detection.classification == 'truth') {
            if (key != 'height') continue
          } else if (key != 'id') {
            content += '<br/>'
          }
          content += `<b>${key}:</b> ${value}`
        }
      }
      return content
    }
  },
  methods: {
    ...mapActions('detection', ['selectDetection', 'addSelectedDetection']),
    positionUpdated(payload: LatLng) {
      this.markerPosition = payload
      this.trails.unshift({ lat: payload.lat, lng: payload.lng })
    },
    detectionClicked() {
      if (!this.interactive) return
      this.selectDetection(this.detectionKey)
    },
    getLocationRadius(confidence: number): number {
      return (1 - confidence) * this.maxRadius
    }
  },
  watch: {
    locationConfidence: {
      handler: function(variance: number) {
        anime({
          targets: this,
          locationConfidenceRadius: variance,
          easing: 'easeOutQuad',
          duration: 500
        })
      }
    },
    position: {
      handler: function(position: IDetectionPosition) {
        this.markerPosition = {
          lat: position.lat,
          lng: position.lng
        }
      }
    }
  }
})
