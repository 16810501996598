












import Vue from 'vue'
import { LPolyline } from 'vue2-leaflet'
import 'leaflet-textpath'
import { IDetection } from '@/store/modules/detection/types'
import { TPositionTuple } from '@/store/modules/maps/types'
import { isNullOrUndefined } from '@/utils/utils'

const props = {
  detection: {
    type: Object as () => IDetection,
    required: true
  },
  dashArray: {
    type: String,
    default: '5 3 1 3'
  },
  lineWeight: {
    type: Number,
    default: 0
  },
  color: {
    type: String,
    default: '#FBA186'
  }
}
export default Vue.extend({
  name: 'Speedleader',
  props,
  components: {
    LPolyline
  },
  computed: {
    showSpeedleader(): boolean {
      const { speed_leader_latitude, speed_leader_longitude } = this.detection
      if (
        isNullOrUndefined(speed_leader_latitude) ||
        isNullOrUndefined(speed_leader_longitude)
      )
        return false
      return speed_leader_latitude !== 0 && speed_leader_longitude !== 0
    },
    speedLeaderLatLngs(): TPositionTuple[] {
      const {
        latitude,
        longitude,
        speed_leader_latitude,
        speed_leader_longitude
      } = this.detection
      return [
        [latitude, longitude],
        [speed_leader_latitude, speed_leader_longitude]
      ]
    }
  },
  mounted() {
    // Access the underlying Leaflet Polyline instance
    this.$nextTick(() => {
      const polyline = this.$refs.speedleader?.mapObject

      if (polyline) {
        // Add TextPath logic to the polyline
        polyline.setText('\u25BA', {
          repeat: true,
          offset: 6,
          attributes: { fill: this.color }
        })
      }
    })
  }
})
