var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.hideLocationVariance)?_c('l-circle',{attrs:{"data-testid":"fusion-detection-dot-location-confidence","lat-lng":[_vm.markerPosition.lat, _vm.markerPosition.lng],"radius":_vm.locationConfidenceRadius,"color":"transparent","fillColor":_vm.detectionColor,"interactive":false,"options":{
      zIndexOffset: 500
    }}}):_vm._e(),_c('LMovingMarker',{ref:_vm.markerRef,class:_vm.useMilStd2525Icons ? 'mil-std-2525-icon' : 'sensor-fusion-icon',attrs:{"data-testid":"fusion-detection-dot","icon":_vm.detectionIcon,"options":{
      zIndexOffset: 700,
      bubblingMouseEvents: false
    },"lat-lng":[_vm.markerPosition.lat, _vm.markerPosition.lng],"duration":650},on:{"mousedown":_vm.detectionClicked,"update:latLng":_vm.positionUpdated}},[(_vm.isDeveloper && _vm.developerSettings.showDetectionDetails)?_c('l-tooltip',{attrs:{"content":_vm.devTooltipContent,"options":{
        permanent: true,
        offset: [0, 5],
        direction: 'bottom',
        className: 'devTooltip'
      }}}):_vm._e()],1),_c('Speedleader',{key:_vm.detectionIconColor,attrs:{"detection":_vm.detection,"color":_vm.detectionIconColor}}),(_vm.selected || (_vm.isDeveloper && _vm.developerSettings.showAllTrails))?[(_vm.selected)?_c('LMarker',{attrs:{"data-testid":"fusion-detection-dot-selector","icon":_vm.selectorIcon,"interactive":false,"options":{
        zIndexOffset: 701,
        bubblingMouseEvents: false
      },"lat-lng":[_vm.markerPosition.lat, _vm.markerPosition.lng]}}):_vm._e(),(_vm.showTrailLine)?_c('l-polyline',{attrs:{"lat-lngs":_vm.trailCoordinates.reverse(),"color":_vm.detectionIconColor,"dashArray":"4","weight":2,"data-testid":"fusion-detection-dot-trail"}}):_vm._e(),(_vm.showTrailDots)?_vm._l((_vm.trails),function(trail,index){return _c('l-circle',{key:(_vm.detectionKey + "_trail-" + index),attrs:{"data-testid":"fusion-detection-dot-trail","color":_vm.detectionColor,"fillColor":_vm.detectionColor,"fillOpacity":1,"radius":10,"lat-lng":[trail.lat, trail.lng]}})}):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }